import React from "react"
import Layout from "../../components/layout"

export default function NotFound() {
  return (
    <Layout>
      <h1>Seite nicht gefunden</h1>
      <p>Ups, wir konnten diese Seite nicht finden!</p>
    </Layout>
  )
}
